// i18next-extract-mark-ns-start markets-motorsport-efuels

import { MARKETS_CAPABILITIES_COMBUSTION_NAV, MOTORSPORT_NAV, COMBUSTION_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorsportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);
	const vulcan = getImage(data.vulcan.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Simulating e-fuel combustion for motorsport</h2>
						<p>
							In today's market with increasing emissions regulations aiming for a net zero future, racing team manufacturers
							are taking the lead in introducing categories requiring the use of e-fuels or non-carbon fuels.
							Adopted by MotoGP which from 2024 requires 40% of fuel to be non-carbon based and Formula 1 which from 2026 will switch to a fully sustainable fuel,
							the design challenge is simulate the combustion properties of e-fuels and compare with traditional fuel performance.
						</p>
						<p>
							In the initial stages of development physical prototype modelling is expensive,
							requiring virtual simulation tools to answer many of the 'What ifs' before committing to a design path.
							Engine combustion simulation is at the heart of vehicle performance predicting available power, speed, fuel usage, emissions,
							friction losses and durability over a range of racing drive cycles.
						</p>

						<p>
							Our software provides in-cylinder combustion tools to model non-carbon fuel performance
							in an engine modified for alternative e-fuels.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that solves the Navier-Stokes equations governing the transfer of mass,
							momentum, and energy for compressible gas flows, and includes sub-models for combustion and emissions.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							VECTIS is a 3D CFD tool that uses cutting edge kinetics, flame propagation and emissions models
							for conventional hydrocarbons, e-fuels and synthetic fuels including hydrogen.
						</p>
					</Trans>

					{vulcan && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vulcan}
								alt="FEARCE-Vulcan"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_4">
						<p>
							FEARCE-Vulcan uses a unique iterative Finite Element solution that considers all the heat paths in detail to deliver a reliable
							and accurate predictive tool for conclusive structural investigations for all fuel types.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORSPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{COMBUSTION_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<p> <small>Photo: motorsportimages.com</small></p>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorsportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorsport-efuels", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorsport/MotorsportImages_1920x548_MotoGP_cropped.jpg" }) {
			...imageBreaker
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageBreaker
		}
		vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
			...imageBreaker
		}
		vulcan: file(relativePath: { eq: "content/rs_fearce_vulcan.png" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorsport" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
